import { useState } from 'react';

import { updateUserCustomField } from '../../../api/user';
import { CustomSwitch } from '../../forms/CustomSwitch';
import Button from '../Button/Button';
import { Checkbox } from '../Checkbox';
import { Modal } from '../Modal/Modal';
import { Typography } from '../Typography/Typography';

type GoToPaidCeremonyModalProps = {
  isOpen: boolean;
  title: string;
  description: string;
  onDontShowAgainKey: string;
  onClose: () => void;
  onGoToPaidCeremonyClick: () => void;
};

export const GoToPaidCeremonyModal = (
  props: GoToPaidCeremonyModalProps
) => {
  const {
    title,
    description,
    isOpen,
    onClose,
    onGoToPaidCeremonyClick,
    onDontShowAgainKey,
  } = props;

  const [dontShowAgain, setDontShowAgain] = useState(false);

  const onHandleClick= async (nextStep: 'close' | 'goToPaidCeremony') => {
    if (dontShowAgain)  await updateUserCustomField(onDontShowAgainKey, 'true');

    if (nextStep == 'close') onClose()
    if (nextStep == 'goToPaidCeremony') onGoToPaidCeremonyClick()
  }


  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      showCloseButton={true}
      title={''}
    >
      <div className='self-center max-w-md flex flex-col justify-center text-center '>
        <>
          <Typography type='display-100'>{title}</Typography>
          <Typography type='body-400' className='mt-4'>
            {description}
          </Typography>
          {/* text-left */}
          <div className=' mt-4'>
            <Checkbox
                label={"Don't show this message again"}
                checked={dontShowAgain } 
                onChange={(_, checked) => {
                    setDontShowAgain(checked);
                }}
            />
          </div>
          
          <div
            className={`flex flex-col-reverse md:flex-row justify-center mt-4`}
          >
            <Button
              className='mt-2 md:mt-0 md:mr-2 md:w-auto w-full'
              onClick={() => onHandleClick('close')}
              variant='secondary'
            >
              Stay Here
            </Button>
            <Button
              className=' md:w-auto w-full'
              onClick={() => onHandleClick('goToPaidCeremony')}
            >
              Go To Paid Ceremony
            </Button>
          </div>
        </>
      </div>
    </Modal>
  );
};
